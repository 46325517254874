// import axios from 'axios';
// import React, { useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
// import authHeader from '../../Auth/auth-header';
// import img1 from './1.png'
// const Exam = () => {
//     const navigate = useNavigate();
//     const labels = [
//         'mother', 'father', 'children', 'grandparents', 'partner', 'spouse',
//         'siblings', 'girlfriends', 'boyfriends', 'friends', 'friendsWithBenifits',
//         'relatives', 'communities', 'boss', 'colleague', 'teacher', 'classmate',
//         'conflicts', 'love', 'intimacy', 'sex', 'commitments', 'goals In Your Relationships'
//     ];
//     const [data1, setData1] = useState([])
//     const initializeFields = () => {
     
//        console.log( JSON.parse(localStorage.getItem('user')).details.id)
     
        
       


//         const initialFields = Array(23).fill().map((_, index) => {
//             const config = { selected: Array(4).fill(""), current: 0 };
//             return { current: config.current, selected: config.selected };
//         });
    
//         return initialFields;
//     };
    

   
//     const [fieldsVisibility, setFieldsVisibility] = useState([]);
//     const [showErrors, setShowErrors] = useState(false);

//     const [id,setId]=useState("")
//     useEffect(() => {

//         axios.get(`http://15.207.84.100:8080/vmkp/api/exam/get` , {
//             headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
//         }).then((response) => {
//             console.log(response.data)
//             const userid=JSON.parse(localStorage.getItem('user')).details.id
//             console.log(response.data.filter((item)=>item.userId==Number(userid)).map((item)=>item.id)[0])
//             setId(response.data.filter((item)=>item.userId==Number(userid)).map((item)=>item.id)[0])

//         }).catch((error)=>{
//             console.log(error)
//                      })



//         axios.get(`http://15.207.84.100:8080/vmkp/api/relationship/getuser/${JSON.parse(localStorage.getItem('user')).details.id}` , {
//             headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
//         }).then((response) => {
//             // console.log(response.data.map((item)=>item.questions));
           
              
//              const cleanedData = Object.fromEntries(
//                 Object.entries(response.data).map(([key, { questions, name }]) => [key, { questions, name }])
//             );
//             console.log(cleanedData)
            
//             const cleanedData1 = Object.fromEntries(
//                 Object.entries(cleanedData).map(([key, { questions  }]) => [key, { questions  }])
//             );
//            console.log(cleanedData1)
              
//            const allQuestionsWithKeys = Object.entries(cleanedData1).flatMap(([key, value]) => 
//             Array.isArray(value.questions) ? 
//             value.questions.map(question => ({ area: key, ...question })) : []
//           );
          
//           const mappedResults = allQuestionsWithKeys.map(question => {
//             const { area, answer } = question;
//             const answerText = answer.text;
        
//             return {
//                 area: area,
//                 Applicable: answerText === 'Not Applicable' || answerText === 'Applicable' ? answerText : "",
//                 Working: answerText === 'Not Working' || answerText === 'Working' ? answerText : "",
//                 Critical: ['Not Critical', 'Somewhat Critical', 'Critical', 'Very Critical', 'Most Critical'].includes(answerText) ? answerText : "",
//                 Future: answerText === 'Necessity' || answerText === 'Wish/Want/Desire' || answerText === 'Dream' || 
//                         answerText === 'Hope' || answerText === 'Miracle' ? answerText : ""
//             };
//         });
        
//         const uniqueData = mappedResults.reduce((acc, item) => {
//             const existing = acc.find(entry => entry.area === item.area);
            
//             if (existing) {
//               existing.Applicable = existing.Applicable || item.Applicable || '';
//               existing.Working = existing.Working || item.Working || '';
//               existing.Critical = existing.Critical || item.Critical || '';
//               existing.Future = existing.Future || item.Future || '';
//             } else {
//               acc.push({
//                 area: item.area,
//                 Applicable: item.Applicable || '',
//                 Working: item.Working || '',
//                 Critical: item.Critical || '',
//                 Future: item.Future || '',
//               });
//             }
            
//             return acc;
//           }, []);
          
//           // Step 2: Map to the desired structure
//           const data = uniqueData.map(question => {
//             const applicable = question.Applicable || '';
//             const working = question.Working || '';
//             const critical = question.Critical || '';
//             const future = question.Future || '';
          
//             // Create an array for selected values based on conditions
//             const selectedArray = [
//               applicable  ,
//               working ,
//               critical  ,
//               future 
//             ];
          
//             // Count how many selected values are not default
//             const currentCount = selectedArray.filter(value => value.length !==0 &&  value.length !==0 &&  value.length !==0 &&  value.length !==0).length-1;
          
//             return {
//               selected: selectedArray,
//               current: currentCount // Store the count of filled conditions
//             };
//           });
      
//           setData1(data)
//           setFieldsVisibility(data.length==0?initializeFields():data)

//         }).catch((error)=>{
// console.log(error)
// setFieldsVisibility(initializeFields())
//         })
//     }, [ ])
    
    
   

//     const handleChange = (rowIndex, selectIndex, value) => {
//         setFieldsVisibility(prev => {
//             const updatedVisibility = prev.map((row, index) => {
//                 if (index === rowIndex) {
//                     const newSelected = [...row.selected];
//                     newSelected[selectIndex] = value;

//                     const nextField = (value !== "Not Applicable" && value !== 'Select' && value !== 'Working') 
//                         ? Math.min(row.current + 1, 3) 
//                         : selectIndex;

//                     return { current: nextField, selected: newSelected };  
//                 }
//                 return row;  
//             });

//             return updatedVisibility;  
//         });
//     };

//     // const handle = () => {
//     //     const allRowsFilled = fieldsVisibility.every(row => 
//     //         row.selected.every(val => val.length !== 0)
//     //     );
    
//     //     setShowErrors(!allRowsFilled); 
    
//     //     // if (allRowsFilled) {
//     //     const result = {
//     //         name: "relationship",
//     //         ...Object.fromEntries(
//     //             labels.map((label, index) => {
//     //                 const selectedValues = fieldsVisibility[index].selected;
    
//     //                 // Find the index of "Not Applicable"
//     //                 const notApplicableIndex = selectedValues.indexOf('Not Applicable');
    
//     //                 // Create a new array where values after "Not Applicable" are replaced with an empty string
//     //                 const updatedAnswers = selectedValues.map((val, i) => {
//     //                     if (i > notApplicableIndex && notApplicableIndex !== -1) {
//     //                         return ""; // Replace with an empty string
//     //                     }
//     //                     return val;
//     //                 });
    
//     //                 const applicableAnswer = updatedAnswers[0] || "";
//     //                 const workingAnswer = updatedAnswers[1] || "";
//     //                 const criticalAnswer = updatedAnswers[2] || "";
//     //                 const futureAnswer = updatedAnswers[3] || "";
    
//     //                 return [
//     //                     label, {
//     //                         name: label,
//     //                         questions: [
//     //                             { text: "Is this applicable?", questionOption: [{ text: "Applicable" }, { text: "Not Applicable" }], answer: { text: applicableAnswer } },
//     //                             { text: "Is this working?", questionOption: [{ text: "Working" }, { text: "Not Working" }], answer: { text: workingAnswer } },
//     //                             { text: "How Critical Is This?", questionOption: [{ text: "Not Critical" }, { text: "Somewhat Critical" }, { text: "Critical" }, { text: "Very Critical" }, { text: "Most Critical" }], answer: { text: criticalAnswer } },
//     //                             { text: "How do you see this in the future?", questionOption: [{ text: "Necessity" }, { text: "Wish/Want/Desire" }, { text: "Dream" }, { text: "Hope" }, { text: "Miracle" }], answer: { text: futureAnswer } }
//     //                         ]
//     //                     }
//     //                 ];
//     //             })
//     //         )
//     //     };
    
//     //     console.log(result);
//     //     // Uncomment for navigation
//     //     // navigate('/result');
//     //     axios.post("http://15.207.84.100:8080/vmkp/api/relationship/post", result, {
//     //         headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
//     //     }).then((response) => {
//     //         console.log(response.status, response.data.token);
//     //     });
//     //     // } else {
//     //     //     console.log(fieldsVisibility);
//     //     //     alert("Please fill out all fields for each row.");
//     //     // }
//     // };
//  const [store, setStore] = useState([]);
 
//     const handleindexof = (e,index1) => {
//     // console.log(e,index1);
//     const findfields=fieldsVisibility.filter((item,index) => index == index1).map((item)=>item.selected)[0].filter((item,index3)=>index3==e).every((item)=>item.length!==0)
//     //  setStore(prevStore => [...prevStore, findfields]);
     
//     setStore(prevStore => {
//         const currentStore = Array.isArray(prevStore) ? prevStore : [];
//         const newStore = [...currentStore, findfields];
        
//         // Check if any value in newStore is false
//         const hasFalseValues = newStore.some(item => item === false);
        
//         // If there are false values, return newStore; otherwise, return true
//         return hasFalseValues ? newStore : true;
//     });


//     console.log(findfields)
//     // if(findfields==false){
//     //    return setShowErrors(!findfields); 

//     // }else{
//     //     return console.log("find")
//     // }
//     // console.log(fieldsVisibility.map(row => 
//     //     row.current
//     // ))
//  }
//  console.log(store)
//      const handle = () => {
//         setStore("");
//         const allRowsFilled = fieldsVisibility.map((row, index1) => 
 
//         fieldsVisibility.filter((item,index) => index == index1).map((item)=>item.selected)[0].filter((item,index3)=>index3==row.current).every((item)=>item.length!==0)


//         );
//         console.log(allRowsFilled.filter((item)=>item==false).length)
       
//     console.log(fieldsVisibility)
//          setShowErrors(!(allRowsFilled.filter((item)=>item==false).length==0?true:false));   
    
//         if (allRowsFilled.filter((item)=>item==false).length==0) {
//         const result = {
//             name: "relationship",
//             exam:[{
//                 "id":Number(id)
//                 }],
//             ...Object.fromEntries(
//                 labels.map((label, index) => {
//                     const selectedValues = fieldsVisibility[index].selected;
    
//                     // Find the indices of "Not Applicable" and "Working"
//                     const notApplicableIndex = selectedValues.indexOf('Not Applicable');
//                     const workingIndex = selectedValues.indexOf('Working');
    
//                     // Create a new array where values after "Not Applicable" and "Working" are replaced with an empty string
//                     const updatedAnswers = selectedValues.map((val, i) => {
//                         if ((i > notApplicableIndex && notApplicableIndex !== -1) || (i > workingIndex && workingIndex !== -1)) {
//                             return ""; // Replace with an empty string
//                         }
//                         return val;
//                     });
    
//                     const applicableAnswer = updatedAnswers[0] || "";
//                     const workingAnswer = updatedAnswers[1] || "";
//                     const criticalAnswer = updatedAnswers[2] || "";
//                     const futureAnswer = updatedAnswers[3] || "";
    
//                     return [
//                         label, {
//                             name: label,
                           
//                             questions: [
//                                 { text: "Is this applicable?", questionOption: [{ text: "Applicable" }, { text: "Not Applicable" }], answer: { text: applicableAnswer  } },
//                                 { text: "Is this working?", questionOption: [{ text: "Working" }, { text: "Not Working" }], answer: { text: workingAnswer } },
//                                 { text: "How Critical Is This?", questionOption: [{ text: "Not Critical" }, { text: "Somewhat Critical" }, { text: "Critical" }, { text: "Very Critical" }, { text: "Most Critical" }], answer: { text: criticalAnswer } },
//                                 { text: "How do you see this in the future?", questionOption: [{ text: "Necessity" }, { text: "Wish/Want/Desire" }, { text: "Dream" }, { text: "Hope" }, { text: "Miracle" }], answer: { text: futureAnswer } }
//                             ]
//                         }
//                     ];
//                 })
//             )
//         };
    
//         console.log(result);
//         // Uncomment for navigation
//         // navigate('/result');
//         axios.post("http://15.207.84.100:8080/vmkp/api/relationship/post", result, {
//             headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
//         }).then((response) => {
//             console.log(response.status, response.data.token);
//             navigate('/health')
//         });
//         } else {
//             console.log(fieldsVisibility);
//             alert("Please fill out all fields for each row.");
//         }
//     };
//     const [isScrolled, setIsScrolled] = useState(false);

//     useEffect(() => {
//         // axios.post("http://15.207.84.100:8080/vmkp/api/exam/post", result, {
//         //     headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
//         // }).then((response) => {
//         //     console.log(response.status, response.data.token);
//         //     navigate('/exam');
//         //   });
      


//         const handleScroll = () => {
//             setIsScrolled(window.scrollY > 100);
//         };

//         window.addEventListener('scroll', handleScroll);
//         window.scrollTo(0, 0);

//         return () => {
//             window.removeEventListener('scroll', handleScroll);
//         };
//     }, []);

//     return (
//         <div className='testexam'>
//         <div className="blueexam">
//             <br></br>
//             <br></br>
//           <div className='maxcenter '  >
//          <div className='rs11' style={{display:'flex',background:'white',justifyContent:'center',flexDirection:'row',gap:50}}>
//          <h1 className='black fontsize 'style={{color:'#3E74D1'}}>Relationships</h1>
//          <hr/>
//          <h1 className='black fontsize'>Health</h1>
//          <hr/>
//          <h1 className='black fontsize'>Wealth</h1>
//          <hr/>
//          <h1 className='black fontsize'>Work and Education</h1>
//          <hr/>
//          <h1 className='black fontsize'>Self Awareness</h1>

//          </div>
//           </div>
//           <br></br>
//             <div className={`display ${isScrolled ? 'blockdisplay' : 'displaynone'}`}>
//                 <p className='black jhk'>
//                     <div className='middle'>
//                         <div className='cont'>
//                             <div>
//                                 <div className='row tesfier'>
//                                     <div className='col black'></div>
//                                     <div className='col black question'>Is this area applicable for you?</div>
//                                     <div className='col black question'>Is this area working for you?</div>
//                                     <div className='col black question'>How critical is this area for you?</div>
//                                     <div className='col black question'>How do you see your near future in this area?</div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </p>
//             </div>
//             <div className='middle'>
//                 <div className='cont'>
//                 <img src={img1} className='imglogo' style={{width:'50px'}} alt="Description of the image" />
//                 <h1 className='black right tyttyt' style={{ color: '#3E74D1' }}>Relationships</h1>
//                     <p className='black right Personal'>This part of the Personal Assessment Test is created for you to reflect on your relationships.</p>
//                     <br />
//                     <div className={`display ${isScrolled ? 'displaynone' : 'blockdisplay'}`}>
//                         <div className='row'>
//                             <div className='col black'></div>
//                             <div className='col black question'>Is this area applicable for you?</div>
//                             <div className='col black question'>Is this area working for you?</div>
//                             <div className='col black question'>How critical is this area for you?</div>
//                             <div className='col black question'>How do you see your near future in this area?</div>
//                         </div>
//                     </div>
//                     {fieldsVisibility.length > 0 ? labels.map((label, index) => (
                        
//                         <div className='row pading12' key={index}>
//                             <div className='col cap black fontweight500'>{label}&nbsp;</div>
                            
//                             <div className='col black'>
//                                 {fieldsVisibility[index].current >= 0 && (
//                                     <select
//                                         className='form-select rs'
//                                         onChange={(e) => handleChange(index, 0, e.target.value)}
//                                         value={fieldsVisibility[index].selected[0] || ""}
//                                         style={{ borderColor: showErrors && fieldsVisibility[index].selected[0] === "" ? 'red' : 'black' }}
//                                     >
//                                         <option disabled value="">--Select--</option>
//                                         <option>Not Applicable</option>
//                                         <option>Applicable</option>
//                                     </select>
//                                 )}
//                             </div>
                            
//                             <div className='col black'>
//                                 {fieldsVisibility[index].current >= 1 && (
//                                     <select
//                                         className='form-select rs'
//                                         onChange={(e) => handleChange(index, 1, e.target.value)}
//                                         value={fieldsVisibility[index].selected[1] || ""}
//                                         style={{ borderColor: showErrors && fieldsVisibility[index].selected[1] === "" ? 'red' : 'black' }}
//                                     >
//                                         <option disabled value="">--Select--</option>
//                                         <option>Not Working</option>
//                                         <option value="Working">Working</option>
//                                     </select>
//                                 )}
//                             </div>
                            
//                             <div className='col black'>
//                                 {fieldsVisibility[index].current >= 2 && (
//                                     <select
//                                         className='form-select rs'
//                                         onChange={(e) => handleChange(index, 2, e.target.value)}
//                                         value={fieldsVisibility[index].selected[2] || ""}
//                                         style={{ borderColor: showErrors && fieldsVisibility[index].selected[2] === "" ? 'red' : 'black' }}
//                                     >
//                                         <option disabled value="">--Select--</option>
//                                         <option>Not Critical</option>
//                                         <option>Somewhat Critical</option>
//                                         <option>Critical</option>
//                                         <option>Very Critical</option>
//                                         <option>Most Critical</option>
//                                     </select>
//                                 )}
//                             </div>
                            
//                             <div className='col black'>
//                                 {fieldsVisibility[index].current >= 3 && (
//                                     <select
//                                         className='form-select rs'
//                                         onChange={(e) => handleChange(index, 3, e.target.value)}
//                                         value={fieldsVisibility[index].selected[3] || ""}
//                                         style={{ borderColor: showErrors && fieldsVisibility[index].selected[3] === "" ? 'red' : 'black' }}
//                                     >
//                                         <option disabled value="">--Select--</option>
//                                         <option>Necessity</option>
//                                         <option>Wish/Want/Desire</option>
//                                         <option>Dream</option>
//                                         <option>Hope</option>
//                                         <option>Miracle</option>
//                                     </select>
//                                 )}
//                             </div>
//                         </div>
//                     )):""}
//                     <br />
//                     <div className='row'>
//                         <div className='col'>
//                             <button className='btn btn-primary bigger' onClick={handle}>Submit</button>
//                         </div>
//                     </div>
//                     <br></br>
//                     <div className='row'>
//                         <div className='col'>
//                             {/* <button className='btn btn-primary' onClick={handle}>Back</button> */}
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//         </div>
//     );
// }

// export default Exam;

import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import authHeader from '../../Auth/auth-header';
import img1 from './1.png'
import { baseurlvmkp } from './Baseurl';
import { Fortesresult, Roamin, tokenexistchecker } from './Workinglogic';
import Swal from 'sweetalert2';
const Exam = () => {
    const navigate = useNavigate();
    const labels = [
        { label: 'Mother', field: 'mother' },
    { label: 'Father', field: 'father' },
    { label: 'Children', field: 'children' },
    { label: 'Grandparents', field: 'grandparents' },
    { label: 'Partner', field: 'partner' },
    { label: 'Spouse', field: 'spouse' },
    { label: 'Siblings', field: 'siblings' },
    { label: `Girlfriend/s`, field: 'girlfriends' },
    { label: 'Boyfriend/s', field: 'boyfriends' },
    { label: 'Friend/s', field: 'friends' },
    { label: 'Friends with Benefits', field: 'friendsWithBenifits' },
    { label: 'Relatives', field: 'relatives' },
    { label: 'Communities', field: 'communities' },
    { label: 'Boss', field: 'boss' },
    { label: 'Colleague/s', field: 'colleague' },
    { label: 'Teacher/s', field: 'teacher' },
    { label: 'Classmate/s', field: 'classmate' },
    { label: 'Conflicts', field: 'conflicts' },
    { label: 'Love', field: 'love' },
    { label: 'Intimacy', field: 'intimacy' },
    { label: 'Sex', field: 'sex' },
    { label: 'Commitments', field: 'commitments' },
    { label: 'Goals in Your Relationships', field: 'golsInYourRelationshps' }
    ];
    const [isLoading, setIsLoading] = useState(false);
    const [data1, setData1] = useState([])
    const initializeFields = () => {
     
       console.log( JSON.parse(localStorage.getItem('user')).details.id)
     
        
       


        const initialFields = Array(23).fill().map((_, index) => {
            const config = { selected: Array(4).fill(""), current: 0 };
            return { current: config.current, selected: config.selected };
        });
    
        return initialFields;
    };
    

   
    const [fieldsVisibility, setFieldsVisibility] = useState([]);
    const [showErrors, setShowErrors] = useState(false);

    const [id,setId]=useState("")
    useEffect(() => {
        Fortesresult(navigate);
        tokenexistchecker(navigate);
   
        const user = JSON.parse(localStorage.getItem('user'));
        if (user && user.details && user.details.id) {
        axios.get(`${baseurlvmkp}/exam/getexampending/${user.details.id}` , {
            headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
        }).then((response) => {
            console.log(response.data.id)
            // const userid=JSON.parse(localStorage.getItem('user')).details.id
            // console.log(response.data.filter((item)=>item.userId==Number(userid)).map((item)=>item.id)[0])
            // setId(response.data.filter((item)=>item.userId==Number(userid)).map((item)=>item.id)[0])
            setId(response.data.id)
            // relationshipid 
            axios.get(`${baseurlvmkp}/relationship/get/${response.data.relationshipid}` , {
                headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
            }).then((response) => {
                // console.log(response.data.map((item)=>item.questions));
               
                  
                 const cleanedData = Object.fromEntries(
                    Object.entries(response.data).map(([key, { questions, name }]) => [key, { questions, name }])
                );
                console.log(cleanedData)
                
                const cleanedData1 = Object.fromEntries(
                    Object.entries(cleanedData).map(([key, { questions  }]) => [key, { questions  }])
                );
               console.log(cleanedData1)
                  
               const allQuestionsWithKeys = Object.entries(cleanedData1).flatMap(([key, value]) => 
                Array.isArray(value.questions) ? 
                value.questions.map(question => ({ area: key, ...question })) : []
              );
              
              const mappedResults = allQuestionsWithKeys.map(question => {
                const { area, answer } = question;
                const answerText = answer.text;
            
                return {
                    area: area,
                    Applicable: answerText === 'Not Applicable' || answerText === 'Applicable' ? answerText : "",
                    Working: answerText === 'Not Working' || answerText === 'Working' ? answerText : "",
                    Critical: ['Not Critical', 'Somewhat Critical', 'Critical', 'Very Critical', 'Most Critical'].includes(answerText) ? answerText : "",
                    Future: answerText === 'Necessity' || answerText === 'Wish/Want/Desire' || answerText === 'Dream' || 
                            answerText === 'Hope' || answerText === 'Miracle' ? answerText : ""
                };
            });
            
            const uniqueData = mappedResults.reduce((acc, item) => {
                const existing = acc.find(entry => entry.area === item.area);
                
                if (existing) {
                  existing.Applicable = existing.Applicable || item.Applicable || '';
                  existing.Working = existing.Working || item.Working || '';
                  existing.Critical = existing.Critical || item.Critical || '';
                  existing.Future = existing.Future || item.Future || '';
                } else {
                  acc.push({
                    area: item.area,
                    Applicable: item.Applicable || '',
                    Working: item.Working || '',
                    Critical: item.Critical || '',
                    Future: item.Future || '',
                  });
                }
                
                return acc;
              }, []);
              
              // Step 2: Map to the desired structure
              const data = uniqueData.map(question => {
                const applicable = question.Applicable || '';
                const working = question.Working || '';
                const critical = question.Critical || '';
                const future = question.Future || '';
              
                // Create an array for selected values based on conditions
                const selectedArray = [
                  applicable  ,
                  working ,
                  critical  ,
                  future 
                ];
              
                // Count how many selected values are not default
                const currentCount = selectedArray.filter(value => value.length !==0 &&  value.length !==0 &&  value.length !==0 &&  value.length !==0).length-1;
              
                return {
                  selected: selectedArray,
                  current: currentCount // Store the count of filled conditions
                };
              });
          
              setData1(data)
              setFieldsVisibility(data.length==0?initializeFields():data)
    
            }).catch((error)=>{
    console.log(error)
    setFieldsVisibility(initializeFields())
            })
        }).catch((error)=>{
            setFieldsVisibility(initializeFields())

            console.log(error)
                     })


                    }
    }, [ ])
    
    
   

    const handleChange = (rowIndex, selectIndex, value) => {
        setFieldsVisibility(prev => {
            const updatedVisibility = prev.map((row, index) => {
                if (index === rowIndex) {
                    const newSelected = [...row.selected];
                    newSelected[selectIndex] = value;

                    const nextField = (value !== "Not Applicable" && value !== 'Select' && value !== 'Working') 
                        ? Math.min(row.current + 1, 3) 
                        : selectIndex;

                    return { current: nextField, selected: newSelected };  
                }
                return row;  
            });

            return updatedVisibility;  
        });
    };

    // const handle = () => {
    //     const allRowsFilled = fieldsVisibility.every(row => 
    //         row.selected.every(val => val.length !== 0)
    //     );
    
    //     setShowErrors(!allRowsFilled); 
    
    //     // if (allRowsFilled) {
    //     const result = {
    //         name: "relationship",
    //         ...Object.fromEntries(
    //             labels.map((label, index) => {
    //                 const selectedValues = fieldsVisibility[index].selected;
    
    //                 // Find the index of "Not Applicable"
    //                 const notApplicableIndex = selectedValues.indexOf('Not Applicable');
    
    //                 // Create a new array where values after "Not Applicable" are replaced with an empty string
    //                 const updatedAnswers = selectedValues.map((val, i) => {
    //                     if (i > notApplicableIndex && notApplicableIndex !== -1) {
    //                         return ""; // Replace with an empty string
    //                     }
    //                     return val;
    //                 });
    
    //                 const applicableAnswer = updatedAnswers[0] || "";
    //                 const workingAnswer = updatedAnswers[1] || "";
    //                 const criticalAnswer = updatedAnswers[2] || "";
    //                 const futureAnswer = updatedAnswers[3] || "";
    
    //                 return [
    //                     label, {
    //                         name: label,
    //                         questions: [
    //                             { text: "Is this applicable?", questionOption: [{ text: "Applicable" }, { text: "Not Applicable" }], answer: { text: applicableAnswer } },
    //                             { text: "Is this working?", questionOption: [{ text: "Working" }, { text: "Not Working" }], answer: { text: workingAnswer } },
    //                             { text: "How Critical Is This?", questionOption: [{ text: "Not Critical" }, { text: "Somewhat Critical" }, { text: "Critical" }, { text: "Very Critical" }, { text: "Most Critical" }], answer: { text: criticalAnswer } },
    //                             { text: "How do you see this in the future?", questionOption: [{ text: "Necessity" }, { text: "Wish/Want/Desire" }, { text: "Dream" }, { text: "Hope" }, { text: "Miracle" }], answer: { text: futureAnswer } }
    //                         ]
    //                     }
    //                 ];
    //             })
    //         )
    //     };
    
    //     console.log(result);
    //     // Uncomment for navigation
    //     // navigate('/result');
    //     axios.post("http://15.207.84.100:8080/vmkp/api/relationship/post", result, {
    //         headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
    //     }).then((response) => {
    //         console.log(response.status, response.data.token);
    //     });
    //     // } else {
    //     //     console.log(fieldsVisibility);
    //     //     alert("Please fill out all fields for each row.");
    //     // }
    // };
 const [store, setStore] = useState([]);
 
    const handleindexof = (e,index1) => {
    // console.log(e,index1);
    const findfields=fieldsVisibility.filter((item,index) => index == index1).map((item)=>item.selected)[0].filter((item,index3)=>index3==e).every((item)=>item.length!==0)
    //  setStore(prevStore => [...prevStore, findfields]);
     
    setStore(prevStore => {
        const currentStore = Array.isArray(prevStore) ? prevStore : [];
        const newStore = [...currentStore, findfields];
        
        // Check if any value in newStore is false
        const hasFalseValues = newStore.some(item => item === false);
        
        // If there are false values, return newStore; otherwise, return true
        return hasFalseValues ? newStore : true;
    });


    console.log(findfields)
    // if(findfields==false){
    //    return setShowErrors(!findfields); 

    // }else{
    //     return console.log("find")
    // }
    // console.log(fieldsVisibility.map(row => 
    //     row.current
    // ))
 }
 console.log(store)
     const handle = () => {
        setStore("");
        const allRowsFilled = fieldsVisibility.map((row, index1) => 
 
        fieldsVisibility.filter((item,index) => index == index1).map((item)=>item.selected)[0].filter((item,index3)=>index3==row.current).every((item)=>item.length!==0)


        );
        console.log(allRowsFilled.filter((item)=>item==false).length)
       
    console.log(fieldsVisibility)
         setShowErrors(!(allRowsFilled.filter((item)=>item==false).length==0?true:false));   
    
        if (allRowsFilled.filter((item)=>item==false).length==0) {
        const result = {
            name: "relationship",
            exam:[{
                "id":Number(id)
                }],
            ...Object.fromEntries(
                labels.map((label, index) => {
                    const selectedValues = fieldsVisibility[index].selected;
    
                    // Find the indices of "Not Applicable" and "Working"
                    const notApplicableIndex = selectedValues.indexOf('Not Applicable');
                    const workingIndex = selectedValues.indexOf('Working');
    
                    // Create a new array where values after "Not Applicable" and "Working" are replaced with an empty string
                    const updatedAnswers = selectedValues.map((val, i) => {
                        if ((i > notApplicableIndex && notApplicableIndex !== -1) || (i > workingIndex && workingIndex !== -1)) {
                            return ""; // Replace with an empty string
                        }
                        return val;
                    });
    
                    const applicableAnswer = updatedAnswers[0] || "";
                    const workingAnswer = updatedAnswers[1] || "";
                    const criticalAnswer = updatedAnswers[2] || "";
                    const futureAnswer = updatedAnswers[3] || "";
    
                    return [
                        label.field, {
                            name: label.label,
                           
                            questions: [
                                { text: "Is this applicable?", questionOption: [{ text: "Applicable" }, { text: "Not Applicable" }], answer: { text: applicableAnswer  } ,   area: label.label,},
                                { text: "Is this working?", questionOption: [{ text: "Working" }, { text: "Not Working" }], answer: { text: workingAnswer },   area: label.label, },
                                { text: "How Critical Is This?", questionOption: [{ text: "Not Critical" }, { text: "Somewhat Critical" }, { text: "Critical" }, { text: "Very Critical" }, { text: "Most Critical" }], answer: { text: criticalAnswer },  area: label.label },
                                { text: "How do you see this in the future?", questionOption: [{ text: "Necessity" }, { text: "Wish/Want/Desire" }, { text: "Dream" }, { text: "Hope" }, { text: "Miracle" }], answer: { text: futureAnswer },  area: label.label },
                               
                            ]

                        }
                    ];
                })
            )
        };
    
        console.log(result);
        // Uncomment for navigation
        // navigate('/result');
        setIsLoading(true);
        axios.post(`${baseurlvmkp}/relationship/post`, result, {
            headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
        }).then((response) => {
            console.log(response.status, response.data.token);
            navigate('/health')
        }) .finally(() => {
            setIsLoading(false); 
          });
        } else {
            console.log(fieldsVisibility);
            alert("Please fill out all fields for each row.");
        }

    };
    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        // axios.post("http://15.207.84.100:8080/vmkp/api/exam/post", result, {
        //     headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
        // }).then((response) => {
        //     console.log(response.status, response.data.token);
        //     navigate('/exam');
        //   });
      


        const handleScroll = () => {
            setIsScrolled(window.scrollY > 100);
        };

        window.addEventListener('scroll', handleScroll);
        window.scrollTo(0, 0);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    const handleLogout = () => {
        localStorage.removeItem('token');
       localStorage.removeItem('user');
        navigate('/');  
     };
     const handleClick = () => {
        Swal.fire({
            title: 'Customer Support',
            html: `
              <p><strong>WhatsApp:</strong> <a href="https://wa.me/919619604038" target="_blank">+91 9619604038</a></p>
              <p><strong>Email:</strong> <a href="https://mail.google.com/mail/?view=cm&fs=1&to=reachus@victormanickam.com" target="_blank">reachus@victormanickam.com</a></p>
            `,
            icon: 'info',
            confirmButtonText: 'Close',
          });
      };
     
    return (
        <div className='testexam'>
        <div className="blueexam">
            <br></br>
            <p className="newtech" onClick={handleClick}>
        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="43" fill="currentColor" className="bi bi-headset" viewBox="0 0 16 16">
          <path d="M8 1a5 5 0 0 0-5 5v1h1a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V6a6 6 0 1 1 12 0v6a2.5 2.5 0 0 1-2.5 2.5H9.366a1 1 0 0 1-.866.5h-1a1 1 0 1 1 0-2h1a1 1 0 0 1 .866.5H11.5A1.5 1.5 0 0 0 13 12h-1a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1h1V6a5 5 0 0 0-5-5"/>
        </svg>
        <span>Help</span>
      </p>
            {/* <br></br> */}
          <div className='maxcenter '  >
         <div className='rs11' style={{display:'flex',background:'white',justifyContent:'center',flexDirection:'row',gap:50}}>
         <h1 className='black fontsize 'style={{color:'#3E74D1'}}>Relationships</h1>
         <hr/>
         <h1 className='black fontsize'>Health</h1>
         <hr/>
         <h1 className='black fontsize'>Work and Education</h1>
         
         <hr/>
         <h1 className='black fontsize'>Wealth</h1>
         <hr/>
         <h1 className='black fontsize'>Self Awareness</h1>

         </div>

       
          </div>
          <br></br>
            <div className={`display ${isScrolled ? 'blockdisplay' : 'displaynone'}`}>
                <p className='black jhk'>
                    <div className='middle'>
                        <div className='cont'>
                            <div>
                                <div className='row tesfier'>
                                    <div className='col black'></div>
                                    <div className='col black question' style={{color:'rgb(9 48 118)'}}>Is this area applicable for you?</div>
                                    <div className='col black question' style={{color:'rgb(9 48 118)'}}>Is this area working for you?</div>
                                    <div className='col black question' style={{color:'rgb(9 48 118)'}}>How critical is this area for you?</div>
                                    <div className='col black question' style={{color:'rgb(9 48 118)'}}>How do you see your near future in this area?</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </p>
            </div>
            <div className="text-end mb-3  logoutpostion">
            <span className="badge newblue p-2" onClick={handleLogout}>
              <span className='logout12'>Logout</span>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-log-out">
                <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"/>
                <polyline points="16 17 21 12 16 7"/>
                <line x1="21" y1="12" x2="9" y2="12"/>
              </svg>
            </span>
          </div>
            <div className='middle'>
                <div className='cont'>
                <img src={img1} className='imglogo' style={{width:'50px'}} alt="Description of the image" />
                <h1 className='black   tyttyt' style={{ color: '#3E74D1' }}>Relationships</h1>
                    <p className='black   Personal'>This part of the Personal Assessment Test is created for you to reflect on your relationships.</p>
                    <br />
                    <div className={`display ${isScrolled ? 'displaynone' : 'blockdisplay'}`}>
                        <div className='row'>
                            <div className='col black'></div>
                            <div className='col black question' style={{color:'rgb(9, 48, 118)'}}>Is this area applicable for you?</div>
                            <div className='col black question' style={{color:'rgb(9, 48, 118)'}}>Is this area working for you?</div>
                            <div className='col black question' style={{color:'rgb(9 48 118)'}}>How critical is this area for you?</div>
                            <div className='col black question' style={{color:'rgb(9 48 118)'}}>How do you see your near future in this area?</div>
                        </div>
                    </div>
                    {fieldsVisibility.length > 0 ? labels.map((label, index) => (
                        
                        <div className='row pading12' key={index}>
                            <div className='col cap black fontweight500 d-flex justify-content-center align-items-center ' style={{    color: 'rgb(9, 48, 118)'}} >{label.label}&nbsp;</div>
                            {console.log(fieldsVisibility)}
                            <div className='col black'>
                                {fieldsVisibility[index].current >= 0 && (
                                    <select
                                        className='form-select rs'
                                        onChange={(e) => handleChange(index, 0, e.target.value)}
                                        value={fieldsVisibility[index].selected[0] || ""}
                                        style={{
                                            border: '3px solid #3e74d13d',                     
                                            borderColor: showErrors && fieldsVisibility[index].selected[0] === "" ? '#ff12005c' : '#3e74d13d'   }}                  
                                                              >
                                        <option disabled value="">--Select--</option>
                                        <option>Not Applicable</option>
                                        <option>Applicable</option>
                                    </select>
                                )}
                            </div>
                            
                            <div className='col black'>
                                {fieldsVisibility[index].current >= 1 && (
                                    <select
                                        className='form-select rs'
                                        onChange={(e) => handleChange(index, 1, e.target.value)}
                                        value={fieldsVisibility[index].selected[1] || ""}
                                        style={{
                                            border: '3px solid #3e74d13d',                     
                                            borderColor: showErrors && fieldsVisibility[index].selected[1] === "" ? '#ff12005c' : '#3e74d13d'
                                          }}                                       >
                                        <option disabled value="">--Select--</option>
                                        <option>Not Working</option>
                                        <option value="Working">Working</option>
                                    </select>
                                )}
                            </div>
                            
                            <div className='col black'>
                                {fieldsVisibility[index].current >= 2 && (
                                    <select
                                        className='form-select rs'
                                        onChange={(e) => handleChange(index, 2, e.target.value)}
                                        value={fieldsVisibility[index].selected[2] || ""}
                                        style={{
                                            border: '3px solid #3e74d13d',                     
                                            borderColor: showErrors && fieldsVisibility[index].selected[2] === "" ? '#ff12005c' : '#3e74d13d'
                                          }}                                       >
                                        <option disabled value="">--Select--</option>
                                        <option>Not Critical</option>
                                        <option>Somewhat Critical</option>
                                        <option>Critical</option>
                                        <option>Very Critical</option>
                                        <option>Most Critical</option>
                                    </select>
                                )}
                            </div>
                            
                            <div className='col black'>
                                {fieldsVisibility[index].current >= 3 && (
                                    <select
                                        className='form-select rs'
                                        onChange={(e) => handleChange(index, 3, e.target.value)}
                                        value={fieldsVisibility[index].selected[3] || ""}
                                        style={{
                                            border: '3px solid #3e74d13d',                     
                                            borderColor: showErrors && fieldsVisibility[index].selected[3] === "" ? '#ff12005c' : '#3e74d13d'
                                          }}                                       >
                                        <option disabled value="">--Select--</option>
                                        <option>Necessity</option>
                                        <option>Wish/Want/Desire</option>
                                        <option>Dream</option>
                                        <option>Hope</option>
                                        <option>Miracle</option>
                                    </select>
                                )}
                            </div>
                        </div>
                    )):""}
                    <br />
                    <div className='row'>
                        <div className='col'>
                         {isLoading?  <button className='btn btn-primary bigger' style={{background:'white'}}  > 
                            <svg viewBox="25 25 50 50" class="spinner-container" style={{width:'21px'}}>
  <circle cx="50" cy="50" r="20" class="spinner-loader"></circle>
</svg>

                            </button>:<button className='btn btn-primary bigger'  onClick={handle}> 
                      Next

                            </button>}

                        </div>
                    </div>
                    <br></br>
                    <div className='row'>
                        <div className='col'>
                            {/* <button className='btn btn-primary' onClick={handle}>Back</button> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
    );
}

export default Exam;
