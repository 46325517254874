import React, { useState, useEffect } from "react";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Select from "react-select";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import Swal from "sweetalert2";
import { baseurlvmkp } from "../Files/Subjects/Baseurl";
import axios from "axios";

const genderOptions = [
  { label: "Male", value: "Male" },
  { label: "Female", value: "Female" },
  { label: "Other", value: "Other" },
];

const maritalStatusOptions = [
  { label: "Single", value: "Single" },
  { label: "Married", value: "Married" },
  { label: "Divorced", value: "Divorced" },
  { label: "Widowed", value: "Widowed" },
];

const occupationOptions = [
  { value: "student", label: "Student" },
  { value: "Employed", label: "Employed" },
  { value: "Unemployed", label: "Unemployed" },
  { value: "Self-employed", label: "Self-employed" },
  { value: "Business-Person", label: "Business Person" },
];

const ModalComponent = ({ isModalOpen, closeModal }) => {
  const initialFormState = {
    first_name: "",
    last_name: "",
    phone_no: "",
    email: "",
    password: "",
    dob: "",
    age: "",
    gender: "",
    marital_status: "",
    country: "",
    states: "",
    city: "",
    occupation: "",
    address: "",
  };
  const customSelectStyles = (textColor = 'black') => ({
    control: (provided, state) => ({
      ...provided,
      color: textColor,
      borderColor: state.isFocused ? textColor : provided.borderColor,
      boxShadow: state.isFocused ? `0 0 0 1px ${textColor}` : provided.boxShadow,
      '&:hover': {
        borderColor: textColor,
      },
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 1300, // Ensure the dropdown appears above modal elements
    }),
    menuList: (provided) => ({
      ...provided,
      maxHeight: '200px', // Set a reasonable height to ensure visibility
      overflowY: 'auto', // Add scroll if the list is too long
    }),
    singleValue: (provided) => ({
      ...provided,
      color: textColor,
      
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? 'white' : textColor,
      backgroundColor: state.isSelected ? textColor : 'white',
      '&:hover': {
        backgroundColor: state.isSelected ? textColor : '#f0f0f0', // Highlight on hover
        color: state.isSelected ? 'white' : textColor,
      },
    }),
  });
  
  
  
  const [formData, setFormData] = useState(initialFormState);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [selectedYear, setSelectedYear] = useState("");
  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [availableDays, setAvailableDays] = useState([]);
  const [erroremail, setErroremail] = useState(false);

  // Fetch countries when the component mounts
  useEffect(() => {
    axios
      .get(`${baseurlvmkp}/country/get`)
      .then((response) =>
        setCountries(
          response.data.map((item) => ({ label: item.name, value: item.id }))
        )
      )
      .catch((error) => console.error("Error fetching countries:", error));
  }, []);

  // Fetch states based on the selected country
  const fetchStates = (countryId) => {
    axios
      .get(`${baseurlvmkp}/state/get/${countryId}`)
      .then((response) =>
        setStates(
          response.data.map((item) => ({ label: item.name, value: item.id }))
        )
      )
      .catch((error) => console.error("Error fetching states:", error));
    setCities([]);
    handleChange("states", "");
    handleChange("city", "");
  };

  // Fetch cities based on the selected state
  const fetchCities = (stateId) => {
    axios
      .get(`${baseurlvmkp}/city/get/${stateId}`)
      .then((response) =>
        setCities(
          response.data.map((item) => ({ label: item.name, value: item.id }))
        )
      )
      .catch((error) => console.error("Error fetching cities:", error));
  };

  // Reset form fields and state when modal closes
  const handleModalClose = () => {
    setFormData(initialFormState);
    setStates([]);
    setCities([]);
    setSelectedYear("");
    setSelectedMonth("");
    setSelectedDate("");
    setAvailableDays([]);
    setErrors({});
    setErroremail(false);
    closeModal();
  };

  // Handle field changes
  const handleChange = (name, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    if (name === "country") fetchStates(value);
    if (name === "states") fetchCities(value);
    if (name === "email") setErroremail(false);
  };

  // Validate form
  const validate = () => {
    const newErrors = {};

    Object.entries(formData).forEach(([key, value]) => {
      if (!value && key !== "dob" && key !== "age") {
        newErrors[key] = `${key.replace("_", " ")} is required`;
      }
    });

    if (!selectedYear || !selectedMonth || !selectedDate)
      newErrors.dob = "Date of Birth is required";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Handle form submission
  const [loadingform, setLoadingform] = useState(false)
  const handleSubmit = () => {
    let age=0;
    if (validate()) {

      const dob = `${selectedYear}-${String(selectedMonth).padStart(2, "0")}-${String(
        selectedDate
      ).padStart(2, "0")}`;
      
      const today = new Date();
      const birthDate = new Date(selectedYear, selectedMonth - 1, selectedDate); // Month is 0-based in JS
      
      // Validate if birthDate is a valid date
      if (isNaN(birthDate.getTime())) {
        console.error("Invalid date of birth");
      } else {
           age = today.getFullYear() - birthDate.getFullYear();
        const monthDifference = today.getMonth() - birthDate.getMonth();
        const dayDifference = today.getDate() - birthDate.getDate();
      
        // Adjust age if the birthday hasn’t occurred yet this year
        if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
          age--;
        }
      
        console.log("Formatted DOB:", dob);
        console.log("Calculated Age:", age);
      }
      

      const payload = {
        ...formData,
       birth_date: dob,
        age,
        country: { id: formData.country },
        states: { id: formData.states },
        city: { id: formData.city },
      };
setLoadingform(true)
      axios
        .post(`${baseurlvmkp}/vmkp/post/customer`, payload)
        .then(() => {
          setLoadingform(false)
          Swal.fire({
            title: "Success!",
            text: "Form submitted successfully!",
            icon: "success",
            confirmButtonText: "OK",
          });
          handleModalClose();
        })
        .catch((error) => {
          setLoadingform(false)
          if (error.response?.data === "Email Already Exist") {
            setErroremail(true);
            Swal.fire({
              title: "Error!",
              text: "This email already exists.",
              icon: "error",
              confirmButtonText: "OK",
            });
          } else {
            setLoadingform(false)
            Swal.fire({
              title: "Error!",
              text: "An error occurred while submitting the form.",
              icon: "error",
              confirmButtonText: "OK",
            });
          }
        });
    } else {
      Swal.fire("Error", "Please fix the errors in the form.", "error");
    }
  };

  // Update available days when year or month changes
  useEffect(() => {
    if (selectedYear && selectedMonth) {
      const daysInMonth = new Date(selectedYear, selectedMonth, 0).getDate();
      setAvailableDays(
        Array.from({ length: daysInMonth }, (_, i) => ({
          label: `${i + 1}`,
          value: `${i + 1}`,
        }))
      );
    } else {
      setAvailableDays([]);
    }
  }, [selectedYear, selectedMonth]);

  // Render modal
  return (

    <Modal
      open={isModalOpen}
      onClose={handleModalClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
    >
      <div  style={{ background: "#fff", padding: "30px", borderRadius: "10px", width: "500px",height: '84vh', overflow: 'scroll' }}>
        <Typography id="modal-title" variant="h5" align="center" gutterBottom>
        Sign Up 
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="First Name"
              name="first_name"
              value={formData.first_name}
              onChange={(e) => handleChange("first_name", e.target.value)}
              error={!!errors.first_name}
              helperText={errors.first_name}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Last Name"
              name="last_name"
              value={formData.last_name}
              onChange={(e) => handleChange("last_name", e.target.value)}
              error={!!errors.last_name}
              helperText={errors.last_name}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Phone Number"
              name="phone_no"
              value={formData.phone_no}
              onChange={(e) => handleChange("phone_no", e.target.value)}
              error={!!errors.phone_no}
              helperText={errors.phone_no}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Email"
              name="email"
              value={formData.email}
              onChange={(e) => handleChange("email", e.target.value)}
              error={!!errors.email}
              helperText={errors.email}
            />
            {erroremail && <span style={{ color: "red" }}>This E-mail Already Exists</span>}
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              type={showPassword ? "text" : "password"}
              label="Password"
              name="password"
              value={formData.password}
              onChange={(e) => handleChange("password", e.target.value)}
              error={!!errors.password}
              helperText={errors.password}
              autoComplete="new-password"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? <FaEyeSlash /> : <FaEye />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <Select
              options={maritalStatusOptions}
              onChange={(option) => handleChange("marital_status", option.value)}
              placeholder="Marital Status"
              styles={customSelectStyles()}
              isSearchable
            />
            {errors.marital_status && <Typography color="error">{errors.marital_status}</Typography>}
          </Grid>
          <Grid item xs={12}>
            <span style={{ color: "black" }}>DOB:</span>
            <div style={{ display: "flex", gap: "6px" }}>
              <Select
                options={Array.from({ length: 100 }, (_, i) => ({
                  label: `${2024 - i}`,
                  value: `${2024 - i}`,
                }))}
                value={selectedYear ? { label: selectedYear, value: selectedYear } : null}
                onChange={(e) => {
                  setSelectedYear(e.value);
                  setSelectedMonth("");
                  setSelectedDate("");
                }}
                styles={customSelectStyles()}

                placeholder="Year"
              />
              <Select
                options={[
                  { label: "January", value: "01" },
                  { label: "February", value: "02" },
                  { label: "March", value: "03" },
                  { label: "April", value: "04" },
                  { label: "May", value: "05" },
                  { label: "June", value: "06" },
                  { label: "July", value: "07" },
                  { label: "August", value: "08" },
                  { label: "September", value: "09" },
                  { label: "October", value: "10" },
                  { label: "November", value: "11" },
                  { label: "December", value: "12" },
                ]}
                value={
                  selectedMonth
                    ? {
                        label: [
                          "January",
                          "February",
                          "March",
                          "April",
                          "May",
                          "June",
                          "July",
                          "August",
                          "September",
                          "October",
                          "November",
                          "December",
                        ][parseInt(selectedMonth) - 1],
                        value: selectedMonth,
                      }
                    : null
                }
                styles={customSelectStyles()}
                onChange={(e) => setSelectedMonth(e.value)}
                placeholder="Month"
              />
              <Select
                options={availableDays}
                value={selectedDate ? { label: selectedDate, value: selectedDate } : null}
                onChange={(e) => setSelectedDate(e.value)}
                placeholder="Day"
                styles={customSelectStyles()}

                isDisabled={!availableDays.length}
              />
            </div>
            {errors.dob && <Typography color="error">{errors.dob}</Typography>}
          </Grid>
          <Grid item xs={6}>
            <Select
              options={genderOptions}
              onChange={(option) => handleChange("gender", option.value)}
              placeholder="Gender"
              styles={customSelectStyles()}

              value={
                formData.gender
                  ? {
                      label: genderOptions.find(
                        (item) => item.value === formData.gender
                      )?.label,
                      value: formData.gender,
                    }
                  : null
              }
              isSearchable
            />
            {errors.gender && <Typography color="error">{errors.gender}</Typography>}
          </Grid>
          <Grid item xs={6}>
            <Select
              options={countries}
              value={countries.find((item) => item.value === formData.country) || null}
              onChange={(option) => handleChange("country", option.value)}
              placeholder="Country"
              styles={customSelectStyles()}
              isSearchable
            />
            {errors.country && <Typography color="error">{errors.country}</Typography>}
          </Grid>
          <Grid item xs={6}>
            <Select
              options={states}
              value={states.find((item) => item.value === formData.states) || null}
              onChange={(option) => handleChange("states", option.value)}
              placeholder="State"
              styles={customSelectStyles()}
              isSearchable
            />
            {errors.states && <Typography color="error">{errors.states}</Typography>}
          </Grid>
          <Grid item xs={6}>
            <Select
              options={cities}
              value={cities.find((item) => item.value === formData.city) || null}
              onChange={(option) => handleChange("city", option.value)}
              placeholder="City"
              styles={customSelectStyles()}
              isSearchable
            />
            {errors.city && <Typography color="error">{errors.city}</Typography>}
          </Grid>
          <Grid item xs={6}>
            <Select
              options={occupationOptions}
              onChange={(option) => handleChange("occupation", option.value)}
              placeholder="Occupation"
              styles={customSelectStyles()}
              value={
                occupationOptions.find((item) => item.value === formData.occupation) || null
              }
              isSearchable
            />
            {errors.occupation && <Typography color="error">{errors.occupation}</Typography>}
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Address"
              name="address"
              value={formData.address}
              onChange={(e) => handleChange("address", e.target.value)}
              error={!!errors.address}
              helperText={errors.address}
            />
          </Grid>
          <Grid item xs={12}>
          { loadingform==false? <Button variant="contained" color="primary" onClick={handleSubmit}>
              Submit
            </Button>:  <p style={{color:'black'}}><div class="loader12"></div></p> }
          </Grid>
        </Grid>
      </div>
    </Modal>
  );
};

export default ModalComponent;
