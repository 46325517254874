import React, { } from "react";
 import { IoEyeSharp } from "react-icons/io5";
import { StaffTableComponents } from "../components/StaffTableComponents";
import axios from "axios";
import { BASE_URL, Relationshpslabels } from "../components/APi";
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
 

function Examdata() {

  let header = [
    { label: "Id", field: "id" },
    { label: "Start Date", field: "start_date" },
    {label:'Duration',field:"duration"},
    {label:'Email',field:"email"}, 
    {label:'Test Status',field:"updatedstatus"},
    
    // id
    // { label: "Result", field: "pdf_location" },
// 
   ];

  const sumofkeys = [{ first: "staffname" }];
  const handle=()=>{
    axios.get(`${BASE_URL}/relationship/get`, {
      headers: { Authorization: 'Bearer ' + localStorage.getItem('staffToken') }
  })
  .then((response) => {
    console.log(response.data.map((item) => item.mother).map((item)=>item.questions).flat().map((item)=>({
      mother:'mother',
     answer: item.answer
    })))
 
    const results = Relationshpslabels.flatMap((item22) => 
      response.data.flatMap((item1) => 
        item1[item22]?.questions?.map((question) => ({
          
          index22: item1[item22].name,
          Applicable: question.answer.text === 'Not Applicable' || question.answer.text === 'Applicable' ? question.answer.text : "",
            Working: question.answer.text === 'Not Working' || question.answer.text === 'Working' ? question.answer.text : "",
            Critical: ['Not Critical', 'Somewhat Critical', 'Critical', 'Very Critical', 'Most Critical'].includes(question.answer.text) ? question.answer.text : "",
            Future: question.answer.text === 'Necessity' || question.answer.text === 'Wish/Want/Desire' || question.answer.text === 'Dream' || 
            question.answer.text === 'Hope' || question.answer.text === 'Miracle' ? question.answer.text : ""
          // father:item22=='father'?'father':'',
        })) || []
      )
    );
    
    console.log(results);
    

    const uniqueData = results.reduce((acc, item) => {
      const existing = acc.find(entry => entry.index22 === item.index22);
      
      if (existing) {
        existing.Applicable = existing.Applicable || item.Applicable || '';
        existing.Working = existing.Working || item.Working || '';
        existing.Critical = existing.Critical || item.Critical || '';
        existing.Future = existing.Future || item.Future || '';
      } else {
        acc.push({
          area: item.index22,
          Applicable: item.Applicable || '',
          Working: item.Working || '',
          Critical: item.Critical || '',
          Future: item.Future || '',
        });
      }
      
      return acc;
    }, []);
    console.log(uniqueData);

    // downloadCsv(response.data.map((item1) => item1.mother).map((item)=>item.questions).flat().map((item)=>({
    //   mother:'mother',
    //  answer1: item.answer.text,
    //  answer2: item.answer.text,
    //  answer3: item.answer.text,
    //  answer4: item.answer.text,
    // })), 'data.csv');
    // downloadCsv(uniqueData, 'data.csv');

    
  })
  .catch((error) => {
      console.error(error);  
  });
  
  }

  function jsonToCsv(jsonData) {
    const rows = [];
    const headers = Object.keys(jsonData[0]);
    rows.push(headers.join(',')); // Add headers

    jsonData.forEach(item => {
        const values = headers.map(header => {
            const escapedValue = String(item[header]).replace(/"/g, '""'); // Escape quotes
            return `"${escapedValue}"`; // Wrap values in quotes
        });
        rows.push(values.join(','));
    });

    return rows.join('\n'); // Join all rows into a single string
}

function downloadCsv(data, filename) {
    const csv = jsonToCsv(data);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    
    a.setAttribute('href', url);
    a.setAttribute('download', filename);
    a.style.display = 'none';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
}

// Assuming response.data is an array of objects
 

  return (
<>
<Sidebar />
    <div className="containerNormal mainContainerlarge">
        <Navbar />
        <div>
      {/* <button onClick={()=>handle()}>Export</button> */}
      <br></br>
      <h1></h1>
    <StaffTableComponents
      head={header}
      title={"Result LIST"}
      axiostabledata={"exam"}
    Add={"+ADD"}
    Create={"/stafftable"}
    Edit={"/Examresult"}
    View={"/Examresult"}
      Sumofkeys={sumofkeys}
    />
    </div>
    </div>
</>



 
  );
}

export default Examdata;
