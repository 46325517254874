// src/Staffcreate.js

import React, { useState, useEffect } from 'react';
import { Form, Button, Container, Card, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { BASE_URL } from '../../components/APi';
import Sidebar from '../../components/Sidebar';
import Navbar from '../../components/Navbar';

const Staffcreate = () => {
    const navigate=useNavigate();
  const [customer, setCustomer] = useState({
    name: '',
    age: '',
    address: '',
    designation: '',
    date_of_birth: '',
    email: '',
    password: '',
    phone_no: '',
    salary: '',
    country: { id: '' },
    state: { id: '' },
    city: { id: '' },
    zipcode: '',
    roles: ['']
  });

  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  useEffect(() => {
    // Fetch countries on component mount
    axios.get(`${BASE_URL}/country/get`)
      .then(response => setCountries(response.data))
      .catch(error => console.error('Error fetching countries:', error));
  }, []);

  const fetchStates = (countryId) => {
    axios.get(`${BASE_URL}/state/get/${countryId}`)
      .then(response => setStates(response.data))
      .catch(error => console.error('Error fetching states:', error));
  };

  const fetchCities = (stateId) => {
    axios.get(`${BASE_URL}/city/get/${stateId}`)
      .then(response => setCities(response.data))
      .catch(error => console.error('Error fetching cities:', error));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === 'country.id') {
      setCustomer((prevCustomer) => ({
        ...prevCustomer,
        country: { id: value },
        state: { id: '' },
        city: { id: '' },
      }));
      fetchStates(value);
      setCities([]); // Clear cities when country changes
    } else if (name === 'state.id') {
      setCustomer((prevCustomer) => ({
        ...prevCustomer,
        state: { id: value },
        city: { id: '' },
      }));
      fetchCities(value);
    } else if (name.startsWith('country') || name.startsWith('state') || name.startsWith('city')) {
      const key = name.split('.')[1];
      setCustomer((prevCustomer) => ({
        ...prevCustomer,
        [name.split('.')[0]]: { ...prevCustomer[name.split('.')[0]], [key]: value }
      }));
    } else if (name === 'roles') {
      setCustomer((prevCustomer) => ({
        ...prevCustomer,
        roles: [value]
      }));
    } else {
      setCustomer((prevCustomer) => ({
        ...prevCustomer,
        [name]: value
      }));
    }
  };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     console.log('Customer Data:', customer);
//   };

// Inside your Staffcreate component

const handleSubmit = (e) => {
  e.preventDefault();

  // Prepare the data in the required format
  const staffData = {
    name: customer.name,
    age: parseInt(customer.age),
    address: customer.address,
    designation: customer.designation,
    date_of_birth: customer.date_of_birth,
    email: customer.email,
    password: customer.password,
    phone_no: customer.phone_no,
    salary: parseFloat(customer.salary),
    country: {
      id: parseInt(customer.country.id)
    },
    states: {
      id: parseInt(customer.state.id)
    },
    city: {
      id: parseInt(customer.city.id)
    },
    zipcode: customer.zipcode,
    roles: customer.roles
  };

  // Make a POST request
  axios.post(`${BASE_URL}/vmkp/post/staff`, staffData)
  
    .then(response => {
      console.log('Staff created successfully:', response.data);
      // Handle success (e.g., show a success message or redirect)
      navigate('/s2/1')
    })
    .catch(error => {
      console.error('There was an error creating the staff:', error);
      // Handle error (e.g., show an error message)
    });
};

  return (
   <>
   
   <Sidebar />
    <div className="containerNormal mainContainerlarge">
        <Navbar />
   <Container className="mt-5 ">
      <Card>
        <Card.Header as="h5">Sign Up</Card.Header>
        <Card.Body>
          <Form onSubmit={handleSubmit}>
            <Row className="mb-3">
              <Col md={6}>
                <Form.Group controlId="formName">
                  <Form.Label>Name</Form.Label>
                  <Form.Control type="text" name="name" value={customer.name} onChange={handleChange} required />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="formAge">
                  <Form.Label>Age</Form.Label>
                  <Form.Control type="number" name="age" value={customer.age} onChange={handleChange} required />
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col md={12}>
                <Form.Group controlId="formAddress">
                  <Form.Label>Address</Form.Label>
                  <Form.Control type="text" name="address" value={customer.address} onChange={handleChange} required />
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col md={6}>
                <Form.Group controlId="formDesignation">
                  <Form.Label>Designation</Form.Label>
                  <Form.Control type="text" name="designation" value={customer.designation} onChange={handleChange} required />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="formDateOfBirth">
                  <Form.Label>Date of Birth</Form.Label>
                  <Form.Control type="date" name="date_of_birth" value={customer.date_of_birth} onChange={handleChange} required />
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col md={6}>
                <Form.Group controlId="formEmail">
                  <Form.Label>Email</Form.Label>
                  <Form.Control type="email" name="email" value={customer.email} onChange={handleChange} required />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="formPhoneNo">
                  <Form.Label>Phone No</Form.Label>
                  <Form.Control type="number" name="phone_no" value={customer.phone_no} onChange={handleChange} required />
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col md={6}>
                <Form.Group controlId="formPassword">
                  <Form.Label>Password</Form.Label>
                  <Form.Control type="password" name="password" value={customer.password} onChange={handleChange} required />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="formSalary">
                  <Form.Label>Salary</Form.Label>
                  <Form.Control type="number" name="salary" value={customer.salary} onChange={handleChange} required />
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col md={4}>
                <Form.Group controlId="formCountryId">
                  <Form.Label>Country</Form.Label>
                  <Form.Control 
                    as="select" 
                    name="country.id" 
                    value={customer.country.id} 
                    onChange={handleChange} 
                    required
                  >
                    <option value="">Select Country</option>
                    {countries.map(country => (
                      <option key={country.id} value={country.id}>{country.name}</option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group controlId="formStateId">
                  <Form.Label>State</Form.Label>
                  <Form.Control 
                    as="select" 
                    name="state.id" 
                    value={customer.state.id} 
                    onChange={handleChange} 
                    required
                  >
                    <option value="">Select State</option>
                    {states.map(state => (
                      <option key={state.id} value={state.id}>{state.name}</option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group controlId="formCityId">
                  <Form.Label>City</Form.Label>
                  <Form.Control 
                    as="select" 
                    name="city.id" 
                    value={customer.city.id} 
                    onChange={handleChange} 
                    required
                  >
                    <option value="">Select City</option>
                    {cities.map(city => (
                      <option key={city.id} value={city.id}>{city.name}</option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col md={6}>
                <Form.Group controlId="formZipcode">
                  <Form.Label>Zipcode</Form.Label>
                  <Form.Control type="text" name="zipcode" value={customer.zipcode} onChange={handleChange} required />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="formRoles">
                  <Form.Label>Roles</Form.Label>
                  <Form.Control 
                    as="select" 
                    name="roles" 
                    value={customer.roles[0]} 
                    onChange={handleChange} 
                    required
                  >
                    <option value="">Select role</option>
                    <option value="ROLE_ADMIN">Admin</option>
                    <option value="ROLE_MARKETING">Marketing</option>
                    <option value="ROLE_FINANCE">Finance</option>
                    <option value="ROLE_ACCOUNTS">Accounts</option>
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>

            <Button variant="primary" type="submit">Sign Up</Button>
          </Form>
        </Card.Body>
      </Card>
    </Container>
   </div>
   
   </>
  );
};

export default Staffcreate;
