import axios from 'axios';
import React, { useState } from 'react';
import "..";
import { useNavigate } from 'react-router-dom';
import { BASE_URL } from '../components/APi';

import '../App_2.css';
const Login = () => {

const navigate=useNavigate();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent the default form submission
  
    try {
      const response = await axios.post(`${BASE_URL}/vmkp/post/signin`, {
        email,
        password,
      });
    // 
      // Assuming the token is returned in response.data.token
      const token = response.data.token;
      const datastaff = response.data;
      if (token) {
        localStorage.setItem('staffToken', token);  
        localStorage.setItem('staffData',  response.data);
        localStorage.setItem('staffDatafororles',  JSON.stringify(response.data));

        console.log('Login successful! Token stored:', token);
        navigate('/Coustomertable/1');

        // window.location.reload();
        console.log( response.data)

        // You can redirect or perform additional actions here
      } else {
        setError('No token received. Login failed.');
      }
    } catch (err) {
      setError('Login failed. Please check your credentials.');
      console.error(err);
    }
  };
  

  
  return (
    <div className="containeruspermax">
      <div className="login-container">
        <div className="login-card">
          <h2 className="login-title">Login</h2>
          {error && <div className="error-message">{error}</div>}
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="email">Email address</label>
              <input
                type="email"
                className="form-input"
                id="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="password">Password</label>
              <input
                type="password"
                className="form-input"
                id="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <button type="submit" className="submit-button">
              Login
            </button>
            <div className="forgot-password">
              <a href="#">Forgot Password?</a>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
