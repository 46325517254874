import React, { } from "react";
 import { IoEyeSharp } from "react-icons/io5";
import { StaffTableComponents } from "../../components/StaffTableComponents";
 import Sidebar from "../../components/Sidebar"
 import Navbar from "../../components/Navbar"
 
function Coustomertable() {

  let header = [
    { label: "Id", field: "id" },
    { label: "User's  Name", field: "full_name" },
    { label: "Email", field: "email" },

    { label: "Mobile No.", field: "phone_no" },
    // { "label": "Action", "field": "action","subaction1": <IoEyeSharp className="indexeye"/> }
  ];
  const sumofkeys = [{ first: "staffname" }];

  return (
   <>
   
   <Sidebar />
    <div className="containerNormal mainContainerlarge">
        <Navbar />
    <div>

    <StaffTableComponents
      head={header}
      title={"User LIST"}
      axiostabledata={"vmkp"}
    Add={"+ ADD"}
    Create={"/userAdd"}
    Edit={"/Useredit"}
    View={"/Useredit"}
      Sumofkeys={sumofkeys}
    />
    
    </div>
    </div>
   </>
  );
}

export default Coustomertable;
