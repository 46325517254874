// src/Staffcreate.js

import React, { useState, useEffect } from 'react';
import { Form, Button, Container, Card, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { BASE_URL } from '../../components/APi';
import Sidebar from '../../components/Sidebar';
import Navbar from '../../components/Navbar';

const Useredit = () => {
    const navigate=useNavigate();
  const { id } = useParams();
  const [customer, setCustomer] = useState({
 first_name: '',
    last_name: '',
    gender:'',
    marital_status: '',
    birth_date: '',
    occupation: '',
    address:'',
    age: '',
      email: '',
    password: '',
    phone_no: '',
     country: { id: '' },
    state: { id: '' },
    city: { id: '' },
    });

  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  
  const genderOptions = [
    { label: 'Male', value: 'Male' },
    { label: 'Female', value: 'Female' },
   ];

  const maritalStatusOptions = [
    { label: "Single", value: "Single" },
    { label: "Married", value: "Married" },
    { label: "Divorced", value: "Divorced" },
    { label: "Widowed", value: "Widowed" },

  ]
  const [staffcust, setStaffcust] = useState([])
//   useEffect(() => {
//     axios.get(`${BASE_URL}/vmkp/get/${id}`)
//     .then(response =>
//         const data = await response.json();
//          const modifiedData = { ...data, state: data.states };
//         delete modifiedData.states;
    
//         setCustomer(modifiedData);
//          fetchStates(response?.data?.country?.id))
//     .catch(error => console.error('Error fetching countries:', error));



//     // Fetch countries on component mount
//     axios.get(`${BASE_URL}/country/get`)
//       .then(response => setCountries(response.data))
//       .catch(error => console.error('Error fetching countries:', error));
//   }, []);
useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch customer data
        const response = await axios.get(`${BASE_URL}/vmkp/get/${id}`);
        
        // Rename 'states' to 'state'
        const modifiedData = { ...response.data, state: response.data.states };
        delete modifiedData.states;
        delete modifiedData.password;

        setCustomer(modifiedData);

        // Fetch states based on country ID
        if (response?.data?.country?.id) {
          fetchStates(response.data.country.id);
        }

        fetchCities(response.data.states.id);
      } catch (error) {
        console.error("Error fetching customer data:", error);
      }
    };

    const fetchCountries = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/country/get`);
        setCountries(response.data);
      } catch (error) {
        console.error("Error fetching countries:", error);
      }
    };

    fetchData();
    fetchCountries();
  }, [ ]);

  const fetchStates = (countryId) => {
    axios.get(`${BASE_URL}/state/get/${countryId}`)
      .then(response => setStates(response.data) )
      .catch(error => console.error('Error fetching states:', error));
  };

  const fetchCities = (stateId) => {
    axios.get(`${BASE_URL}/city/get/${stateId}`)
      .then(response => setCities(response.data))
      .catch(error => console.error('Error fetching cities:', error));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === 'country.id') {
      setCustomer((prevCustomer) => ({
        ...prevCustomer,
        country: { id: value },
        state: { id: '' },
        city: { id: '' },
      }));
      fetchStates(value);
      setCities([]); // Clear cities when country changes
    } else if (name === 'state.id') {
      setCustomer((prevCustomer) => ({
        ...prevCustomer,
        state: { id: value },
        city: { id: '' },
      }));
      fetchCities(value);
    } else if (name.startsWith('country') || name.startsWith('state') || name.startsWith('city')) {
      const key = name.split('.')[1];
      setCustomer((prevCustomer) => ({
        ...prevCustomer,
        [name.split('.')[0]]: { ...prevCustomer[name.split('.')[0]], [key]: value }
      }));
    } else if (name === 'gender') {
      setCustomer((prevCustomer) => ({
        ...prevCustomer,
        gender: [value][0]
      }));
    } else if (name === 'marital_status') {
        setCustomer((prevCustomer) => ({
          ...prevCustomer,
          marital_status: [value][0]
        }));
      }
      else if (name === 'occupation') {
        setCustomer((prevCustomer) => ({
          ...prevCustomer,
          occupation: [value][0]
        }));
      } 
      else {
      setCustomer((prevCustomer) => ({
        ...prevCustomer,
        [name]: value
      }));
    }
  };
console.log(staffcust)
//   const handleSubmit = (e) => {
//     e.preventDefault();
//     console.log('Customer Data:', customer);
//   };

// Inside your Staffcreate component
const calculateAge = (dob) => {
    const birthDate = new Date(dob);
    const today = new Date();
  
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    const dayDiff = today.getDate() - birthDate.getDate();
  
    // Adjust if birthday hasn't occurred yet this year
    if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
      age--;
    }
  
    return age;
  };
const handleSubmit = (e) => {
  e.preventDefault();
//   first_name: '',
//     last_name: '',
//     birth_date: '',
//     gender: '',
//     address: '',
//     marital_status: '',
//     occupation: '',
//     email: '',
//     password: '',
//     phone_no: '',
//     country: "",
//     states: "",
//     city: ""
  // Prepare the data in the required format
  const staffData = {
    first_name: customer.first_name,
    last_name:customer.last_name,
    gender: customer.gender,
    marital_status: customer.marital_status,
    birth_date: customer.birth_date,
    occupation: customer.occupation,
    age: calculateAge(customer.birth_date),
     address: customer.address,
      email: customer.email,
    password: customer.password,
    phone_no: customer.phone_no,
     country: {
      id: parseInt(customer.country.id)
    },
    states: {
      id: parseInt(customer.state.id)
    },
    city: {
      id: parseInt(customer.city.id)
    },
    };

  // Make a POST request
  axios.put(`${BASE_URL}/vmkp/put/${id}`, staffData, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('staffToken') }
  })
  .then(response => {
    console.log('Staff updated successfully:', response.data);
    // Handle success (e.g., show a success message or redirect)
    navigate('/Coustomertable/1');
  })
  .catch(error => {
    console.error('There was an error updating the staff:', error);
    // Handle error (e.g., show an error message)
  });

};
console.log(customer)
  return (
   <>
   
   <Sidebar />
    <div className="containerNormal mainContainerlarge">
        <Navbar />
   <Container className="mt-5 ">
      <Card>
        <Card.Header as="h5">Edit User</Card.Header>
        <Card.Body>
          <Form onSubmit={handleSubmit}>
            <Row className="mb-3">
              <Col md={6}>
                <Form.Group controlId="formName">
                  <Form.Label>First Name</Form.Label>
                  <Form.Control type="text" name="first_name" value={customer.first_name} onChange={handleChange} required />
                </Form.Group>
              </Col>
              <Col md={6}>
              <Form.Group controlId="formName">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control type="text" name="last_name" value={customer.last_name} onChange={handleChange} required />
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col md={6}>
              <Form.Group controlId="formRoles">
                  <Form.Label>Gender</Form.Label>
                  <Form.Control 
                    as="select" 
                    name="gender" 
                    value={customer.gender} 
                    onChange={handleChange} 
                    required
                  >
                    <option value="">Select role</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                   </Form.Control>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="formAddress">
                  <Form.Label>Address</Form.Label>
                  <Form.Control type="text" name="address" value={customer.address} onChange={handleChange} required />
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col md={6}>
              <Form.Group controlId="formRoles">
                  <Form.Label>Marital Status</Form.Label>
                  <Form.Control 
                    as="select" 
                    name="marital_status" 
                    value={`${customer.marital_status}`} 
                    onChange={handleChange} 
                    required
                  >
                    <option value="">Select Status</option>
                    <option value="Single">Single</option>
                    <option value="Married">Married</option>
                    <option value="Divorced">Divorced</option>
                    <option value="Widowed">Widowed</option>
                   </Form.Control>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="formDateOfBirth">
                  <Form.Label>Date of Birth</Form.Label>
                  <Form.Control type="date" name="birth_date" value={customer.birth_date} onChange={handleChange} required />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="formDateOfBirth">
                  <Form.Label>Age:{calculateAge(customer.birth_date)==-1?' ':calculateAge(customer.birth_date)}
                  </Form.Label>
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col md={6}>
                <Form.Group controlId="formEmail">
                  <Form.Label>Email</Form.Label>
                  <Form.Control type="email" name="email" value={customer.email} onChange={handleChange} required />
                </Form.Group>
              </Col>
              <Col md={6}>
              <Form.Group controlId="formPassword">
                  <Form.Label>Password</Form.Label>
                  <Form.Control type="password" name="password" value={customer.password} onChange={handleChange}   />
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col md={6}>
              <Form.Group controlId="formPhoneNo">
                  <Form.Label>Phone No</Form.Label>
                  <Form.Control type="number" name="phone_no" value={customer.phone_no} onChange={handleChange} required />
                </Form.Group>
              </Col>
              <Col md={6}>
             
         
                  <Form.Group controlId="formRoles">
                  <Form.Label>Occupation</Form.Label>
                  <Form.Control 
                    as="select" 
                    name="occupation" 
                    value={`${customer.occupation}`} 
                    onChange={handleChange} 
                    required
                  >
                    <option value="1">Select Occupation</option>
                    <option value="student">Student</option>
                    <option value="Employed">Employed</option>
                    <option value="Unemployed">Unemployed</option>
                    <option value="Self-employed">Self-employed</option>
                    <option value="Business Person">Business Person</option>

                  </Form.Control>
                </Form.Group>
            
              </Col>
            </Row>

            <Row className="mb-3">
              <Col md={4}>
                <Form.Group controlId="formCountryId">
                  <Form.Label>Country</Form.Label>
                  <Form.Control 
                    as="select" 
                    name="country.id" 
                    value={customer?.country?.id} 
                    onChange={handleChange} 
                    required
                  >
                    <option value="">Select Country</option>
                    {countries.map(country => (
                      <option key={country.id} value={country.id}>{country.name}</option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group controlId="formStateId">
                  <Form.Label>State</Form.Label>
                  <Form.Control 
                    as="select" 
                    name="state.id" 
                    value={customer?.state?.id} 
                    onChange={handleChange} 
                    required
                  >
                    <option value="">Select State</option>
                    {states.map(state => (
                      <option key={state.id} value={state.id}>{state.name}</option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group controlId="formCityId">
                  <Form.Label>City</Form.Label>
                  <Form.Control 
                    as="select" 
                    name="city.id" 
                    value={customer?.city?.id} 
                    onChange={handleChange} 
                    required
                  >
                    <option value="">Select City</option>
                    {cities.map(city => (
                      <option key={city.id} value={city.id}>{city.name}</option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col md={6}>
                {/* <Form.Group controlId="formZipcode">
                  <Form.Label>Zipcode</Form.Label>
                  <Form.Control type="text" name="zipcode" value={customer.zipcode} onChange={handleChange} required />
                </Form.Group> */}
              </Col>
              <Col md={6}>
          
              </Col>
            </Row>

            <Button variant="primary" type="submit">Sign Up</Button>
          </Form>
        </Card.Body>
      </Card>
    </Container>
   </div>
   
   </>
  );
};

export default Useredit;
