import axios from 'axios';
import React, { useEffect, useState } from 'react'
 import { Link } from 'react-router-dom';
import { useNavigate, useParams } from "react-router-dom";
// import { CSVLink } from 'react-csv';
 import { Card, CardBody, Table } from 'react-bootstrap';
import { BASE_URL } from './APi';
import { IoMdSearch } from 'react-icons/io';
import { TiArrowSortedDown, TiArrowSortedUp } from 'react-icons/ti';

export const StaffTableComponents = ({ head, axiostabledata, Edit, View, Add,title,Create,Sumofkeys,warehousehdata}) => {
  const { id } = useParams();
  const navigate = useNavigate();


  const [currentPage, setCurrentPage] = useState(1);
  const [error, setError] = useState(encodeURIComponent(' '));
  const [number, setnumber] = useState([])
  const [total, setotal] = useState([])
  const [size, setsize] = useState([])
  const [getid, setgetid] = useState(0)
  const [limit, setlimit] = useState(10)
  const [sort1, setsort1] = useState(false)
  const [sort, setsort] = useState("asc")
  const [fieldnme, setfeilds] = useState("id")
  const [data, setdata] = useState([])
  const [superid, setsuperid] = useState(0)
  const [exportpage, setexport] = useState([])
  const [data1, setdata1] = useState(10)
  const [entries, setentries] = useState([])
  const { pointindex } = useParams();
  const pagination = (msg) => {
    setgetid(msg)
  }
  const handlechange = (entry) => {
    setdata1(entry)
    setlimit(entry)
  }

  const count = [{ "value": 10 }, { "value": 20 }, { "value": 30 }, { "value": 50 }]

  const searchhandle = (search) => {
    if (search.length == 0) {
      setError(encodeURIComponent(' '))
    } else {
      setError(search)


    }
  }

  const handlesort = (field) => {
    setsort1(!sort1)
    if (sort1) {
      setsort("asc")
      setfeilds(field)
    } else {
      setsort("desc")
      setfeilds(field)
    }

 

  }

  useEffect(() => {
    const token = localStorage.getItem('staffToken');


    const startTime = new Date().getTime();
    axios
    .get(
      `${BASE_URL}/${axiostabledata}/page/${Number(currentPage) - 1}/${Number(limit)}/${sort}/${fieldnme}/${error}`, 
      {
        headers: { Authorization: 'Bearer ' + localStorage.getItem('staffToken') }
      }
    )
    .then(response => {
      const endTime = new Date().getTime();
      const elapsedTime = endTime - startTime;
      console.log(`Request took ${elapsedTime} milliseconds`);
      setentries(response.data.Enteries);
      console.log(response);
      
      if (response.data.Index === undefined) {
        setsize(0);
        setnumber(0);
        console.log(response);
        setdata(response.data.data);
      } else {
        setsize(response.data.Pages);
        setnumber(response.data.Pages);
        setdata(response.data.Index);
      }
    })
    .catch(error => {
      console.error(error);
    });
  
  
  }, [getid, limit, fieldnme, sort,currentPage,error]);


  const data5 = Object.assign(...Sumofkeys.map(({ first }) => ({
    [first]: data.reduce((acc, curr) => acc + (parseFloat(curr[first]) || 0), 0)
})));

console.log(data5);
  // c 


  const handle = (id) => {
    console.log(id)
    if(axiostabledata!=="myorder"){
    if (id >= 0) {
       if(Number(pointindex)==0){
      navigate(`${Edit}/${id}/${Number(currentPage)}`)}else{
        navigate(`${Edit}/${id}/${currentPage}`)
      }
    } else {
      if(Number(pointindex)==0){
        navigate(`${View}/${id.view}/${Number(currentPage)}`)}else{
          navigate(`${View}/${id.view}/${currentPage}`)
        }
    }
  }

  }
  const bruce = data.map((item, rowIndex) => (
    <tr className="the"  >
      {head.map((item2) =>
       
        <td className="datatabletd"  style={{display:item2.field=="action"?axiostabledata=="mininmumstock" ? "none" : "block":""}} onClick={() => { handle(item2.field == "action" ? { "view": item.id } : item.id); }}>{item2.field == "action" ? item2.subaction1 : item[item2.field]}</td>
      )}
    </tr>
  ));
  useEffect(() => {
    console.log(currentPage)
      setCurrentPage(Number(pointindex))
    setgetid(Number(pointindex))

  }, [])

  const handlePageChange = (page) => { 
    setCurrentPage(page);
 
    setsuperid(page - 1);
    pagination(page - 1);
  };
  const renderPageNumbers = () => {
    const pages = [];
    const maxPagesToShow = 3;

    for (let i = Math.max(1, currentPage - 2); i <= Math.min(number - 1, currentPage + 1); i++) {
      pages.push(
        <li key={i} className={i===Number(currentPage) ? 'newway' : ''} onClick={() => handlePageChange(i)}>
          {i}
        </li>
      );
    }
    // pages+1
    return pages;
  };
  return (
    <>
        <Card >
        <Card.Header>
            <h1>{title}</h1>
            <div>
           { Create.length !== 0? <Link to={Create}
           ><button className="button-70"  style={{ display: axiostabledata === "erfedf" ? "none" : "block" }}> {Add.length?Add:""}</button></Link>:""}
           
            </div>
            </Card.Header>
          <CardBody >
            <div className="forentriesandaddbutton">
              <div className="bottole">
                <p>Show entries</p>
                <select className="newselect" onChange={(e) => handlechange(e.target.value)}>
                  {count.map((item) => { return <option>{item.value}</option> })}
                </select>
              </div>
              <div className="search ">
            <IoMdSearch style={{marginLeft: "1rem", marginTop:"-5px", position: "absolute"}} color="#4285f4" size="1.9em" />
                <input
              id="search-bar" 
              type="text"
             placeholder="Search" 
            className=" index searchBar searchindex" 
            onChange={(e) => searchhandle(e.target.value)} />
              </div>
            </div>
            <Table  responsive  className="custom-table" >
              <thead>
                <tr>
                  {head.map((head, index) => (
                    <th style={{display:head.field=="action"?axiostabledata=="mininmumstock" ? "none" : "block":""}} onClick={() => { handlesort(head.field == "action" ? "id" : head.field) }} key={index}>{head.label}{sort1 ? <TiArrowSortedUp /> : <TiArrowSortedDown/>}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {bruce}
              </tbody>
             </Table> 
            <div className="flex">
              <div className="showentri">
                {data1 == 10 ? <p>Showing {superid * data1 + 1} to {superid * data1 + 10} of {entries} entries</p> : ""}
                {data1 == 20 ? <p>Showing {superid * data1 + 1} to {superid * data1 + 20} of {entries} entries</p> : ""}
                {data1 == 30 ? <p>Showing {superid * data1 + 1} to {superid * data1 + 30} of {entries} entries</p> : ""}
                {data1 == 50 ? <p>Showing {superid * data1 + 1} to {superid * data1 + 50} of {entries} entries</p> : ""}
              </div>
              <div className="pagination">
                <ul className="pagination">
                  <li onClick={() => handlePageChange(currentPage > 1 ? currentPage - 1 : 1)}>
                    {'Previous'}
                  </li>
                  {renderPageNumbers()}
                  {number}
                  <li onClick={() => handlePageChange(currentPage == number ? number : currentPage + 1)}>{'Next'}</li>

                </ul>

              </div>
            </div>

          </CardBody>
        </Card>
      </>
  )
}