import React, { memo, useEffect, useState } from "react";
import { FiMenu } from "react-icons/fi";
import { FaRegBell } from "react-icons/fa";
import { FiMessageSquare } from "react-icons/fi";
import { PiSignOut } from "react-icons/pi";
import { IoIosArrowDown } from "react-icons/io";
import { LiaEdit } from "react-icons/lia";

import { sidebarItems } from "./SidebarData";
import { SiTruenas } from "react-icons/si";
import Dashboard from '../pages/Dashboard';
import TwoStepPopupForm  from '../pages/PurchaseOrder';
import { Route, Routes, Link} from 'react-router-dom';
import { useNavigate } from "react-router-dom";

const Sidebar=() =>{

  // const token =localStorage.getItem('user');
  //     let id  =JSON.parse(token).details.id;



  const [active, setactive] = useState(false);
  const [toggle, settoggle] = useState(false);
  const [indexNumber, setindexNumber] = useState([]);
  const [submenu, setsubmenu] = useState(false);
  const [hoverAsideBody, sethoverAsideBody] = useState(false);
  const handleUserNav = (e) => {
    setactive(!active);
  };
  const navigate = useNavigate();
  const handleToggleSize = (e) => {
    settoggle(!toggle);
  };
  useEffect(() => {
    const asideBody = document.querySelector(".aside-body");

    const asideEle = document.querySelector("aside");

    asideBody.addEventListener("mouseenter", () => {
      if (asideEle.classList.contains("activeToggle")) {
        asideBody.classList.add("hoverAsideBody");
      }
    });
    asideBody.addEventListener("mouseleave", () => {
   
        asideBody.classList.remove("hoverAsideBody");
      
    });
  }, [toggle]);
const handlelogut=()=>{
  localStorage.removeItem("staffToken");
  localStorage.removeItem("staffData");
  // window.location.reload();
  navigate('/login');
}
 
  const handleClick = (e, index) => {
    setindexNumber(index);
    
    return setsubmenu(!submenu);
  };


  return (
    <>
      <aside className={`sidebarContainer  ${toggle ? "activeToggle" : ""}`}>
        <header className="asideheader">
          {/* <a rel="preload" class=" aside-logo" href="/">
            do<span>ar</span>
          </a> */}
                  <img style={{ padding: '12px', height: '67px' }} onClick={()=>{ navigate('/')}} src='https://static.wixstatic.com/media/5715af_a286bfb446374a96877a4193a1ef0933~mv2.png/v1/fill/w_114,h_60,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/PAT%20Logo.png' alt="Description of the image" />
                  {/* <img style={{ padding: '12px', height: '40px' }} src='https://static.wixstatic.com/media/77a25b_ec2fa92086954799bf72699afde7a666~mv2.png/v1/fill/w_191,h_34,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/VMKGBLUE.png' alt="Description of the image" /> */}

          <button
            className="toggle-menu-btn"
            id="toggle-btn"
            onClick={handleToggleSize}
          >
            <FiMenu />
          </button>
        </header>
        <div className="aside-body">
          <div className="scrollbar">
            <div className="scroll-container">
              <div className="aside-inner-body">
                <div className="aside-user">
                  <div className="user-info">
                    <div className="avatar">
                      <span className="avatar-inital">A</span>
                    </div>
                    <div className="aside-alerts">
                      <div className="react-component-tooltip">
                        <div class="tooltip_element">
                          <a href="#"  style={{display:'none'}}  className="icon-header new">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              stroke-width="2.3px"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            >
                              <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path>
                            </svg>
                          </a>

                          <span class="tooltip">
                            You have 2 unread messages
                          </span>
                        </div>
                        <div class="tooltip_element">
                          <a href="#"   style={{display:'none'}}  className="icon-header new">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              stroke-width="2.3px"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            >
                              <path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path>
                              <path d="M13.73 21a2 2 0 0 1-3.46 0"></path>
                            </svg>
                          </a>

                          <span class="tooltip">
                            You have 4 new notifications
                          </span>
                        </div>
                        <div class="tooltip_element " onClick={()=>handlelogut()}>

                          <a href="#" className="icon-header">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              stroke-width="2.3px"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            >
                              <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
                              <polyline points="16 17 21 12 16 7"></polyline>
                              <line x1="21" y1="12" x2="9" y2="12"></line>
                            </svg>
                          </a>

                          <span class="tooltip signout">sign out</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="aside-loggedIn-user">
                    <div className="aside-loggedIn-user-data"  style={{display:'none'}} >
                      <button
                        type="button"
                        className="loggedIn-user-info-btn"
                        onClick={handleUserNav}
                      >
                        <h6 class="loggedIn-user-name">Administrator</h6>
                        <IoIosArrowDown className="toggle-user-menu" />
                      </button>
                      <p className="desgination-user"> </p>
                    </div>
                    <div
                      className={
                        active
                          ? "aside-loggedIn-user-nav"
                          : "aside-loggedIn-user-nav-inactive"
                      }
                    >
                      {/* <nav className="nav-loggedIn">
                        <Link to="#" className="nav-link">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          >
                            <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
                            <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
                          </svg>{" "}
                          <span>Edit Profile</span>
                        </Link>
                        <Link to="#" className="nav-link">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          >
                            <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                            <circle cx="12" cy="7" r="4"></circle>
                          </svg>
                          <span>View Profile</span>
                        </Link>
                        <Link to="#" className="nav-link">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          >
                            <circle cx="12" cy="12" r="3"></circle>
                            <path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path>
                          </svg>
                          <span>Account Settings</span>
                        </Link>
                
                        <Link to="#" className="nav-link" onClick={()=>handlelogut()}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          >
                            <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
                            <polyline points="16 17 21 12 16 7"></polyline>
                            <line x1="21" y1="12" x2="9" y2="12"></line>
                          </svg>
                          <span>Sign Out</span>
                        </Link>
                      </nav> */}
                    </div>
                  </div>
                </div>
                <ul className="aside-navbar">
                  { sidebarItems.map((lis, index) => {
             
                    return (
                      <li key={index} className="aside-nav-item-li">
                        <Link to='#' className="aside-navbar-heading">
                          {lis.title}
                        </Link>
                        <ul className="submenu">
                          {lis.dataUl.map((item, indexs) => {
                     
                            return (
                              <li
                                key={indexs}
                                // className={`${
                                //   submenu && indexNumber === indexs
                                //     ? `with-sub open`
                                //     : ''
                                // } ${item.ulItems?'with-sub':'witout-sub'} `}
                                
                                className={`${item.ulItems?'with-sub ':'witout-sub'} ${
                                    submenu && indexNumber === indexs
                                      ? `open`
                                      : ''
                                  }`}
                                onClick={
                                  item.ulItems
                                    ? (e) => handleClick(e, indexs)
                                    : (e) => {
                                        e.preventDefault();
                                      }
                                }
                              >
                                <Link className=" aside-navbar-link" to={item.pasths}>
                                  {item.ulIcon}
                                  <span>{item.name}</span>
                                </Link>
                                <ul
                                  key={index}
                                  className={
                                    submenu && indexNumber === indexs
                                      ? "mega-submenu open"
                                      : "mega-submenu"
                                  }
                                >
                                  {item.ulItems ? (
                                    <>
                                      {item.ulItems.map((ele, indexss) => {
                                        return (
                                          <li>
                                            <Link
                                              className=" aside-navbar-link"
                                              to="#"
                                            >
                                              {ele}
                                            </Link>
                                          </li>
                                        );
                                      })}
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </ul>
                              </li>
                            );
                          })}
                        </ul>
                      </li>
                    );
                  })}
              
                </ul>
              </div>
            </div>
          </div>
        </div>
      </aside>
    </>
  );
}
export default memo(Sidebar)